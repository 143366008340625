import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EMAIL_PATTERN } from '../constants';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { NbToastrService } from '@nebular/theme';
import { UtilsService } from '../../../@core/utils/utils.service';
import { RegisterService } from '../../../@core/backend/portal/services/register.service';
import { LayoutService } from '../../../@core/utils';

@Component({
    selector: 'ngx-register',
    styleUrls: ['./register.component.scss'],
    templateUrl: './register.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxRegisterComponent implements OnInit {
    public MASKS = MASKS;
    isMobile: boolean = this.layoutService.isMobile();
    submitted = false;
    errors: string[] = [];
    messages: string[] = [];
    user: any = {};
    loadingButtonRegister: boolean = false;
    registerForm: FormGroup;

    constructor(
        protected layoutService: LayoutService,
        private registerService: RegisterService,
        private fb: FormBuilder,
        private router: Router,
        public utilsService: UtilsService,
        private toastrService: NbToastrService
    ) {
    }
    
    get nomeFornec() { return this.registerForm.get('nomeFornec'); }
    get nomeFornecFantasia() { return this.registerForm.get('nomeFornecFantasia'); }
    get ruaFornec() { return this.registerForm.get('ruaFornec'); }
    get complementoFornec() { return this.registerForm.get('complementoFornec'); }
    get bairroFornec() { return this.registerForm.get('bairroFornec'); }
    get cepFornec() { return this.registerForm.get('cepFornec'); }
    get telefoneFornec() { return this.registerForm.get('telefoneFornec'); }
    get cidadeFornec() { return this.registerForm.get('cidadeFornec'); }
    get paisFornec() { return this.registerForm.get('paisFornec'); }
    get ufFornec() { return this.registerForm.get('ufFornec'); }
    get cnpjFornec() { return this.registerForm.get('cnpjFornec'); }
    get emailFornec() { return this.registerForm.get('emailFornec'); }

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.registerForm = this.fb.group({
            nomeFornec: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(40)]),
            nomeFornecFantasia: this.fb.control('', [Validators.maxLength(40)]),
            ruaFornec: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(60)]),
            complementoFornec: this.fb.control('', [Validators.maxLength(10)]),
            bairroFornec: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(40)]),
            cepFornec: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(10)]),
            telefoneFornec: this.fb.control('', [Validators.required, NgBrazilValidators.telefone]),
            cidadeFornec: this.fb.control('', [Validators.required, Validators.minLength(4), Validators.maxLength(40)]),
            paisFornec: this.fb.control('BRA', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]),
            ufFornec: this.fb.control('', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
            cnpjFornec: this.fb.control('', [Validators.required, NgBrazilValidators.cnpj]),
            emailFornec: this.fb.control('', [Validators.required, Validators.pattern(EMAIL_PATTERN),
            ]),
        });
    }

    save() {
        this.loadingButtonRegister = true;
        const form = { ...this.registerForm.value };

        const cepForm = String(form.cepFornec);
        const cep = cepForm.replace('.', '');
        form.cepFornec = cep;

        const cnpjcpf = String(form.cnpjFornec);
        const numStr = cnpjcpf.replace(/[^0-9]/g, '');
        form.cnpjFornec = numStr;

        this.registerService.save(form)
            .subscribe(retorno => {
                if (retorno.solcad == "200") {
                    this.toastrService.success('Sucesso!', 'Obrigado por realizar o seu cadastro, assim que que confirmarmos ele aqui, você receberá um email com os dados de acesso!', { duration: 10000 });
                    setTimeout(() => this.router.navigate(['/auth/login']), 10000);
                } else {
                    this.toastrService.danger('Erro!', retorno.message, { duration: 5000 });
                }
                this.loadingButtonRegister = false;
            }, _ => {
                this.toastrService.danger('Erro!', 'Ocorreu um erro inesperado, tente novamente...', { duration: 5000 });
                this.loadingButtonRegister = false;
            });
    }

    checkAndFindAddress(value: string) {
        if (value.length === 10) {
            this.buscarEndereco(value);
        }
    }

    public async buscarEndereco(cep: string) {
        const cepFormat = cep.replace('.', '');

        const enderecoCompleto = await this.utilsService.obterLogradouro(cepFormat)
            .toPromise();

        this.registerForm.patchValue({
            ruaFornec: enderecoCompleto.logradouro,
            cidadeFornec: enderecoCompleto.localidade,
            ufFornec: enderecoCompleto.uf,
            bairroFornec: enderecoCompleto.bairro,
        });
    }

    onlynumber(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /^[0-9.]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
}