<nb-card class="user-search-card" (click)="stopPropagation($event)">
  <nb-card-header>
    <div class="row">
      <h5 class="col-9 d-flex align-items-center">
        {{isMobile ? 'Selecione Usuário' : 'Selecione um usuário'}}
      </h5>
      
      <div class="col-3 col text-right">
        <button nbButton status="primary" (click)="cancel()" hero>
          <nb-icon icon="close-outline"></nb-icon>
        </button>
      </div>
    </div>
  </nb-card-header>

  <nb-card-body>
    <ngx-responsive-table 
      class="request-smart-table"
      [settings]="settings"
      [source]="source"
      (edit)="onEdit($event)">
    </ngx-responsive-table>
  </nb-card-body>
</nb-card>
