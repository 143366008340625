<div class="container text-center">
  <img [src]="logo" alt="logo" height="100"/>
</div>

<h1 id="title" class="title">Atualizar Senha</h1>
<p class="sub-title">Escolha sua nova senha e confirme para altera-la</p>

<nb-alert *ngIf="showMessages?.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title">
    <nb-icon icon="alert-triangle-outline"></nb-icon>
    <b> Erro! </b>
  </p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">Ocorreu um erro ao alterar a senha, tente novamente</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages?.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title">
    <nb-icon icon="checkmark-circle-outline"></nb-icon>
    <b> Senha Alterada! </b>
  </p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">Senha atualizada com sucesso</li>
  </ul>
</nb-alert>

<div [formGroup]="resetPasswordForm" aria-labelledby="title">
  <div class="form-control-group">
    <label class="label" for="input-password">Senha</label>
    <input nbInput autofocus fullWidth id="input-password" formControlName="password" type="password" class="first"
      [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''" placeholder="Senha">
    <ngx-validation-message label="Senha"
      [showMinLength]="password?.hasError('minlength') && password.touched"
      [showMaxLength]="password?.hasError('maxlength') && password.touched"
      [showRequired]="password?.errors?.required && password.touched"
      [minLength]="minLength" [maxLength]="maxLength">
    </ngx-validation-message>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-re-password">Confirmar Senha</label>
    <input nbInput fullWidth id="input-re-password" formControlName="confirmPassword" type="password" placeholder="Confirme sua senha"
      [status]="confirmPassword.dirty ? (confirmPassword.invalid || password.value != confirmPassword.value  ? 'danger' : 'success') : ''">
    <ngx-validation-message label="Senha de confirmação"
      [showMinLength]="confirmPassword?.hasError('minlength') && confirmPassword.touched"
      [showMaxLength]="confirmPassword?.hasError('maxlength') && confirmPassword.touched"
      [showRequired]="confirmPassword?.errors?.required && confirmPassword.touched"
      [minLength]="minLength" [maxLength]="maxLength">
    </ngx-validation-message>
    <p  class="error-message caption status-danger" *ngIf="password.value != confirmPassword.value && confirmPassword.touched">
      A senha e a confirmação de senha não correspondem!
    </p>
  </div>

  <button nbButton status="success" fullWidth [class.btn-pulse]="submitted" (click)="resetPass()"
    [disabled]="submitted || !resetPasswordForm.valid || password.value != confirmPassword.value">
    Atualizar Senha
  </button>
</div>
