import { Component, Input } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { ChatService } from "../chat.service";
import { EndConversationDialogComponent } from "../end-conversation-dialog/end-conversation-dialog.component";
import { LayoutService } from "../../../@core/utils";

@Component({
  selector: "ngx-chat-list-item",
  templateUrl: "./chat-list-item.component.html",
  styleUrls: ["./chat-list-item.component.scss"],
})
export class ChatListItemComponent {
  public isMobile = this.layoutService.isMobile();

  get sessionId() {
    return this.chatService.sessionId;
  }

  constructor(
    private dialogService: NbDialogService,
    private chatService: ChatService,
    private layoutService: LayoutService
  ) {}

  @Input("item") queue!: any;

  join(channelId: any, event: any): void {
    event.preventDefault();
    this.chatService.join(channelId);
  }

  endConversation(queue: any, event: any) {
    event.preventDefault();

    this.dialogService
      .open(EndConversationDialogComponent, {
        context: {
          message: `Deseja encerrar a conversa com '${queue.providerName}?'`,
        },
        closeOnBackdropClick: false,
      })
      .onClose.subscribe((stausConfirm) => {
        if (stausConfirm === true) {
          this.chatService.endConversation(queue.channelId);
        }
      });
  }
}
