import { Injectable } from "@angular/core";

@Injectable()
export class FunctionsService {
  readonly defaultFloat: number = 0.0;
  readonly defaultFloatString: string = '0,00';

  stringToFloat(str: string | number): number {
    if (!str || str == null || str == undefined) {
      return this.defaultFloat;
    }

    if (typeof str == 'number')
      return str;

    str = str.replace(new RegExp('[.]+', 'g'), '');
    str = str.replace('R$ ', '');
    str = str.replace(',', '.');

    return parseFloat(str);
  }

  floatToString(float: number): string {
    if (!float || float == null || float == undefined) {
      return this.defaultFloatString;
    }

    const strFloat = float.toString();

    if (!strFloat.includes('.')) {
      return strFloat + ',00';
    }

    let str = float.toString().replace('.', ',');
    const decimalPlaces = str.split(',');

    if (decimalPlaces[1].length == 1) {
      str += '0';
    }

    return str;
  }
}