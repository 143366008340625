import { Component, Input, OnInit, Optional, Host, SkipSelf, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, ControlContainer, AbstractControl } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LayoutService } from '../../@core/utils';
import { NbLayoutScrollService } from '@nebular/theme';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NgxSearchSelectComponent,
    multi: true
  }]
})
export class NgxSearchSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  data: any[] = [];

  @Input()
  value: string;

  @Input()
  title: string;

  @Input()
  formControlName: string;

  @Input()
  enableSearch: boolean = true;

  @Input()
  status?: string;

  @Input()
  placeholder?: string;

  private readonly destroy$ = new Subject<void>();
  private disabled: boolean = false;
  private formControl: AbstractControl;
  private resizeObserver: ResizeObserver;
  private innerHeight: number = window.innerHeight;
  private onChange$: (newValue: any) => void;
  private onTouched$: () => void;

  public readonly isMobile: boolean = this.layoutService.isMobile();
  public hideContent: boolean = true;
  public tempData: any[] = [];
  public searchValue: string = '';
  public selectedTitle: string = '';
  public selectedValue: any = null;
  public pxWidth: string = '';
  public maxHeight: number;

  private get selfComponent(): HTMLElement { return this.elementRef.nativeElement; }

  public get isDisabled(): string { return this.disabled ? 'disabled' : ''; }
  public get isPlaceholder(): string { return this.selectedValue === null ? 'placeholder' : ''; }

  constructor(
    private layoutService: LayoutService,
    private elementRef: ElementRef,
    private scrollService: NbLayoutScrollService,
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  ngOnInit(): void {
    this.setContentWidth();

    this.tempData = this.data;
    this.selectedTitle = this.placeholder;

    if (this.controlContainer && this.formControlName) {
      this.formControl = this.controlContainer.control.get(this.formControlName);
    }

    if (!this.isMobile) {
      this.setMaxHeight();

      this.scrollService.onScroll()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.setDropdownPosition());
      
      this.resizeObserver = new ResizeObserver(() => {
        this.setDropdownPosition();
      });

      this.resizeObserver.observe(this.selfComponent.querySelector('.dropdown-content'));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.resizeObserver?.disconnect();
  }

  openDropdown() {
    this.hideContent = this.disabled ? true : !this.hideContent;
  }

  selectItem(value: any) {
    this.formControl.setValue(value);

    this.onChange$(value);
    this.onTouched$();

    this.openDropdown();
  }

  setFilter(): void {
    this.tempData = !this.searchValue ? this.data : this.data.filter((x) => 
      x[this.title].toLowerCase().includes(this.searchValue.toLowerCase())
    );
  }

  writeValue(obj: any): void {
    const selectedData = this.data.find(x => x[this.value] == obj);

    this.selectedValue = obj;
    this.selectedTitle = selectedData ? selectedData[this.title] : '';
  }

  registerOnChange(fn: any): void {
    this.onChange$ = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched$ = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private setMaxHeight(): void {
    const dataLength = this.data.length;
    
    const heightCap = 320;
    const pixelsPerLetter = 8.18
    const baseLineHeight = 38;
    const additionalLineHeight = 24;

    if (dataLength > (this.enableSearch ? 8 : 6)) {
      this.maxHeight = heightCap;
      return;
    }

    let totalHeight = this.enableSearch ? 60 : 0;

    this.data.forEach((opt) => {
      const titlePixels = opt[this.title].length * pixelsPerLetter;
      const titleAdditionalLines = titlePixels / parseInt(this.pxWidth.replace('px', ''));
      totalHeight += baseLineHeight + (parseInt(titleAdditionalLines.toString(), 10) * additionalLineHeight);
    })

    this.maxHeight = totalHeight > heightCap ? heightCap : totalHeight;
  }

  private setContentWidth(): void {
    const dropdown = this.selfComponent.getElementsByClassName('dropdown-selector').item(0) as HTMLElement;
    this.pxWidth = dropdown.offsetWidth + 4 + 'px';
  }

  private setDropdownPosition(): void {
    const dropdownSelector = this.selfComponent.querySelector('.dropdown-selector') as HTMLElement;
    const dropdownContent = this.selfComponent.querySelector('.dropdown-content') as HTMLElement;

    const selectorRect = dropdownSelector.getBoundingClientRect();

    if (selectorRect.bottom + this.maxHeight > this.innerHeight) {
      dropdownContent.style.top = `calc(${selectorRect.top}px - ${dropdownContent.offsetHeight}px)`;
    } else {
      dropdownContent.style.top = `${selectorRect.bottom}px`;
    }
  }

  @HostListener('window:resize', ['$event'])
  private _onWindowResize(_: Event) {
    this.innerHeight = window.innerHeight;
    this.setContentWidth();
  }

  @HostListener('document:click', ['$event'])
  private _onDocumentClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hideContent = true;
      this.onTouched$();
    }
  }
}
