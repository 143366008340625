<nb-card>
  <nb-card-header>{{ mensagem }}</nb-card-header>

  <nb-card-body>
      <ngx-responsive-table
          class="request-smart-table"
          [settings]="settings"
          [source]="source"
          (edit)="onEdit($event)">
      </ngx-responsive-table>
  </nb-card-body>

  <nb-card-footer class="mobile-modal-footer">
    <button class="cancel" nbButton status="danger" (click)="cancel()">Cancelar</button>
  </nb-card-footer>
</nb-card>
