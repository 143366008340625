import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { LocalDataSource, ServerDataSource } from 'ng2-smart-table';
import { LayoutService } from '../../@core/utils';
import { ColumnSettings, Paginator, TableColumn } from '../../@core/interfaces/common/responsive-table';
import { DatePipe } from '@angular/common';
import { NbThemeService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-responsive-table',
  templateUrl: './responsive-table.component.html',
  styleUrls: ['./responsive-table.component.scss'],
})
export class NgxResponsiveTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  settings: any;

  @Input()
  source: any[] | LocalDataSource;

  @Input()
  title?: string;

  @Output()
  edit: EventEmitter<{ data: any }> = new EventEmitter();

  @Output()
  delete: EventEmitter<{ data: any }> = new EventEmitter();

  @Output()
  add: EventEmitter<{ data: any }> = new EventEmitter();

  private readonly unsubscribe$ = new Subject<void>();
  private readonly datePipe: DatePipe = new DatePipe('en-US');
  private arraySource: any[] = [];
  private page: number = 1;

  public readonly isMobile: boolean = this.layoutService.isMobile();
  public readonly emptySettings: any = this.pagerOnlySettings;
  public isDarkTheme: boolean;
  public dataSource: LocalDataSource;
  public orderBy: string = 'ASC';
  public sortBy: string = '';
  public search: string = '';

  public get hideHeader(): boolean {
    return !this.settings.hideSubHeader;
  }

  public get sourceIterrator(): any {
    return this.isDataSource ? this.dataSource : { data: this.arraySource.slice(...Object.values(this.arrayPaginator)) };
  }

  private get arrayPaginator(): Paginator {
    return { start: (this.page - 1) * 3, end: this.page * 3};
  }

  private get isDataSource(): boolean {
    return this.source instanceof LocalDataSource;
  }

  private get columns(): any {
    return this.settings.columns;
  }

  private get pagerOnlySettings(): any {
    return {
      noDataMessage: 'Nenhum registro encontrado.',
      actions: {
        add: false,
        delete: false,
        edit: false,
      },
      pager: {
        perPage: 3,
      },
    };
  }

  constructor(private layoutService: LayoutService, private themeService: NbThemeService) {}

  ngOnInit(): void {
    this.dataSource = this.isDataSource ? this.source as LocalDataSource : new LocalDataSource(this.source as any[]);
    this.isDarkTheme = this.themeService.currentTheme == 'dark';

    this.themeService.onThemeChange()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((theme) =>
        this.isDarkTheme = theme.name == 'dark'
    );

    if (this.isMobile && !this.isDataSource) {
      this.arraySource = this.source as any[];
      this.setArrayPaginator();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const sourceChanges = changes['source'];
    if (!this.isDataSource && sourceChanges) {
      this.dataSource = new LocalDataSource(sourceChanges.currentValue);
      if (this.isMobile) {
        this.arraySource = sourceChanges.currentValue;
        this.setArrayPaginator();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getRowClass(register: any): string {
    return 'rowClassFunction' in this.settings ? this.settings.rowClassFunction({ data: register }) : '';
  }

  getColumns(item: any): TableColumn[] {
    const columns: TableColumn[] = [];

    if (!this.columns) {
      return [];
    }

    const columnSettings = Object.entries(this.columns).map(([key, value]) => {
      return { key, value } as ColumnSettings;
    });

    columnSettings.forEach((cs) => 
      columns.push({
        key: cs.key,
        value: this.getSubProperty(item, cs.key),
        type: cs.value.type,
        sort: cs.value.sort
      })
    );

    return columns;
  }

  formatTitle(key: any): any {
    return this.columns[key].title;
  }

  formatValue(col: TableColumn, row: any): any {
    if (col.type == 'date') {
      return this.datePipe.transform(col.value, 'dd/MM/yyyy hh:mm');
    }

    const columnConfig = this.columns[col.key];

    if (columnConfig && 'valuePrepareFunction' in columnConfig) {
      return columnConfig.valuePrepareFunction(col.value, row);
    }

    return col.value;
  }

  setSort(key: string, sort: boolean): void {
    if (sort == false) {
      return;
    }
    
    if (this.sortBy != key) {
      this.orderBy = 'ASC';
    }

    this.sortBy = key;
    
    if (this.isDataSource) {
      this.dataSource.setSort([{ field: key, direction: this.orderBy }], true);
    } else {
      this.arraySource = this.filterArraySource(this.source as any[]);
      this.dataSource.load(this.arraySource);
    }

    this.orderBy = this.orderBy == 'ASC' ? 'DESC' : 'ASC';
  }

  setFilter(): void {
    if (this.isDataSource) {
      this.dataSource.setFilter([{ field: 'Global', search: this.search }], false, true);
    } else {
      this.arraySource = this.filterArraySource(this.source as any[]);
      this.dataSource.load(this.arraySource);
    }
  }

  editEmissor(val: any) {
    this.edit.emit({ data: val });
  }

  deleteEmissor(val: any) {
    this.delete.emit({ data: val });
  }

  addEmissor(val: any) {
    this.add.emit({ data: val });
  }

  private setArrayPaginator(): void {
    this.dataSource.onChanged()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((change) => {
        switch (change.action) {
          case 'page':
            this.page = this.dataSource.getPaging().page;
            break;
          case 'filter':
            this.page = 1;
            break;
        }
    });
  }

  private filterArraySource(array: any[]): any[] {
    return array
      .filter((obj: Object) => Object.values(obj).some((prop: Object) => prop.toString().includes(this.search)))
      .sort((objA, objB) => {
        if (!this.sortBy) return 0;
        const comparasion = objA[this.sortBy] < objB[this.sortBy] ? -1 : objA[this.sortBy] > objB[this.sortBy] ? 1 : 0;
        return this.orderBy == 'ASC' ? comparasion : -comparasion;
      });
  }

  private getSubProperty(obj: Object, keys: string): any {
    for (const key of keys.split('.')) {
      if (obj.hasOwnProperty(key)) {
        obj = obj[key];
      } else {
        return null;
      }
    }

    return obj;
  }
}
