import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbChatModule,
  NbIconModule,
  NbInputModule,
} from "@nebular/theme";
import { ChatRoutingModule } from "./chat-routing.module";
import { ChatListComponent } from "./chat-list/chat-list.component";
import { ChatListItemComponent } from "./chat-list-item/chat-list-item.component";
import { ChatIconProviderComponent } from "./chat-icon-provider/chat-icon-provider.component";
import { ChatIconBuyerComponent } from "./chat-icon-buyer/chat-icon-buyer.component";
import { ChatPopupComponent } from "./chat-popup/chat-popup.component";
import { DragDirective } from "./chat-popup/drag.directive";
import { ChatService } from "./chat.service";
import { ChatMessageComponent } from "./chat-message/chat-message.component";
import { FormsModule } from "@angular/forms";
import { EndConversationDialogComponent } from "./end-conversation-dialog/end-conversation-dialog.component";

const NB_MODULES = [
  NbChatModule.forRoot({
    messageGoogleMapKey: "AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY",
  }),
  NbInputModule,
  NbButtonModule,
  NbIconModule,
  NbBadgeModule,
  NbCardModule,
];

@NgModule({
  declarations: [
    ChatIconProviderComponent,
    ChatIconBuyerComponent,
    ChatListComponent,
    ChatPopupComponent,
    DragDirective,
    ChatListItemComponent,
    ChatMessageComponent,
    EndConversationDialogComponent,
  ],
  exports: [
    ChatIconProviderComponent,
    ChatIconBuyerComponent,
    ChatListComponent,
    ChatPopupComponent,
    DragDirective,
    ChatListItemComponent,
    ChatMessageComponent,
    EndConversationDialogComponent,
  ],
  imports: [CommonModule, FormsModule, ChatRoutingModule, ...NB_MODULES],
  providers: [ChatService],
  entryComponents: [EndConversationDialogComponent],
})
export class ChatModule {}
