
import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Address } from './address';
import { Company, RequestResponse } from './company';
import { PaymentCondition } from './payment-condition';
import { BankData } from './bankData';
import { EmailResponse } from '../common/emailResponse';


export interface Provider {
  id: number;
  cnpjCpf: string;
  groupCompany: string;
  name: string;
  secundName: string;
  typeRegistration: string;
  active: boolean;
  foneNumber: string;
  email: string;
  codIntegration: string;
  stateRegistration: string;
  ufStateRegistration: string;
  formPayment: string;
  paymentConditionId: number;
  comments: string;
  accountNumber: number;
  accountType: string;
  bankKey: number;
  digit: string;
  address?: Address;
  bankDatas?: Array<BankData>;
}

export interface Invite {
  email: string;
}

export interface UserProvider {
  userId: number;
  providerId: number;
}

export abstract class ProviderData {
  abstract get gridDataSource(): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<Provider>>;
  abstract get(id: number): Observable<Provider>;
  abstract update(registeData: Provider): Observable<RequestResponse>;
  abstract create(registeData: Provider): Observable<RequestResponse>;
  abstract delete(id: number): Observable<boolean>;
  abstract addUser(registeData: UserProvider): Observable<any>;
  abstract removeUser(registeData: UserProvider): Observable<any>;
  abstract inviteProvider(registeData: Invite): Observable<EmailResponse>;
}
