<nb-card>
  <nb-card-header>
    <div class="row">
      <h5 class="col-10 d-flex align-items-center">Convidar Fornecedor</h5>

      <div class="col-1 text-right">
        <button nbButton status="primary" class="btn-close" (click)="cancel()" hero>
          <i class="nb-close"></i>
        </button>
      </div>
    </div>
  </nb-card-header>

  <nb-card-body>
    <div class="row">
      <div class="col-md-12">
        <label class="label">Email do Fornecedor</label>
        <nb-form-field>
          <nb-icon [status]="emailValue && !validEmail ? 'danger': ''" nbPrefix icon="email-outline" pack="eva"></nb-icon>
          <input nbInput type="text" class="form-control" [(ngModel)]="emailValue" [status]="emailValue && !validEmail ? 'danger': ''" placeholder="Email" [disabled]="loadingButton"/>
        </nb-form-field>
        <div class="warning" [ngStyle]="emailValue && !validEmail ? {} : {visibility: 'hidden'}">
          <p class="caption status-danger">
            Digite um endereço de e-mail válido!
          </p>
        </div>
      </div>
    </div>
  </nb-card-body>

  <nb-card-footer>
    <button [disabled]="(loadingButton || emailValue) && !validEmail" [nbSpinner]="loadingButton" nbSpinnerSize="tiny" (click)="sendEmail()" nbButton hero status="success">
      <span [ngStyle]="!loadingButton ? {} : { opacity: 0 }"> Enviar </span>
    </button>
  </nb-card-footer>
</nb-card>
