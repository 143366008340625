import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../common/api/http.service';


@Injectable()
export class ChatApi {
    private readonly apiController: string = 'chat';

    constructor(private api: HttpService) { }

    public checkState(session: string): Observable<any> {
        return this.api.post(`${this.apiController}/check-state/${session}`, null);
    }

    public addQueue(session: string): Observable<any> {
        return this.api.post(`${this.apiController}/queue/add/${session}`, null);
    }

    public cancelQueue(session: string): Observable<any> {
        return this.api.post(`${this.apiController}/queue/cancel/${session}`, null);
    }

    public join(channelId: string, session: string): Observable<any> {
        return this.api.post(`${this.apiController}/join/${channelId}/${session}`, null);
    }

    public sendMessage(channelId: string, payload: any): Observable<any> {
        return this.api.post(`${this.apiController}/message/${channelId}`, payload);
    }

    public endConversation(channelId: string): Observable<any> {
        return this.api.post(`${this.apiController}/end/${channelId}`, null);
    }

    public sendIsTyping(channelId: string, session: string, state: boolean) {
        return this.api.post(`${this.apiController}/typing/${channelId}/${session}`, { isTyping: state });
    }
}
