
import { NbMenuItem } from '@nebular/theme';
import { NbAccessChecker, NbAclService, NbRoleProvider } from '@nebular/security';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';

@Injectable()
export class PagesMenu {
    isAdmin: boolean;
    isBuyer: boolean;
    isProvider: boolean;
    roles: [];
    protected readonly unsubscribe$ = new Subject<NbMenuItem>();

    constructor(private accessChecker: NbAccessChecker, private authService: NbAuthService, private aclService: NbRoleProvider, private router: Router) {
        this.refredhAcl();
    }

    refredhAcl() {
        this.aclService.getRole().subscribe(async retRoles => {
            const ret1 = retRoles.indexOf('provider');
            const ret2 = retRoles.indexOf('admin');
            const ret3 = retRoles.indexOf('buyer');
            
            if (ret1 >= 0) {
                this.isProvider = true;
            }
            if (ret2 >= 0) {
                this.isAdmin = true;
            }
            if (ret3 >= 0) {
                this.isBuyer = true;
            }
                        
            if (ret1 === -1 && ret2 === -1 && ret3 === -1) {
                this.router.navigate(['auth/login']);
            }
        });
    }

    getMenu2(): Observable<NbMenuItem[]> {
        this.isAdmin = false;
        this.isBuyer = false;
        this.isProvider = false;
        return this.aclService.getRole()
            .pipe(map(retRoles => {
                const ret1 = retRoles.indexOf('provider');
                const ret2 = retRoles.indexOf('admin');
                const ret3 = retRoles.indexOf('buyer');
                if (ret1 >= 0) {
                    this.isProvider = true;
                }
                if (ret2 >= 0) {
                    this.isAdmin = true;
                }
                if (ret3 >= 0) {
                    this.isBuyer = true;
                }
                if (ret1 === -1 && ret2 === -1 && ret3 === -1) {
                    this.router.navigate(['auth/login']);
                }

                return this.getMenu();
            }));
    }

    getMenu(): NbMenuItem[] {
        const dashboard: NbMenuItem[] = [
            {
                title: 'Dashboard',
                icon: 'home-outline',
                link: '/pages/dashboard',
                home: true,
                children: undefined,
            },
        ];

        const adminMenu: NbMenuItem[] = [
            {
                title: 'Usuários',
                icon: 'people-outline',
                link: '/pages/users/list',
                children: undefined,
            },
        ]

        const buyerMenu: NbMenuItem[] = [
            {
                title: 'Fornecedores',
                icon: 'car-outline',
                link: '/pages/providers/list',
                children: undefined,
            },
            {
                title: 'Dados EME4',
                icon: 'archive-outline',
                children: [
                    {
                        title: 'Condições de Pagamento',
                        icon: 'credit-card-outline',
                        link: '/pages/paymentconditions/list',
                        children: undefined,
                    },
                    {
                        title: 'Unidades de Medida',
                        icon: 'pantone-outline',
                        link: '/pages/unitmeasurament/list',
                        children: undefined,
                    },
                    {
                        title: 'Produtos',
                        icon: 'cube-outline',
                        link: '/pages/items/list',
                        children: undefined,
                    },
                    {
                        title: 'Moedas',
                        icon: 'credit-card-outline',
                        link: '/pages/coins/list',
                        children: undefined,
                    },
                    {
                        title: 'Filiais',
                        icon: 'home-outline',
                        link: '/pages/company/list',
                        children: undefined,
                    },
                ]
            },
            {
                title: 'Cotações de Compra',
                icon: 'shopping-cart-outline',
                link: '/pages/quotes/list',
                children: undefined,
            }
        ]

        const providerUser: NbMenuItem[] = [
            {
                title: 'Meus Fornecedores',
                icon: 'car-outline',
                link: '/pages/providers/list',
                children: undefined,
            },
        ];

        const providerMenu: NbMenuItem[] = [
            {
                title: this.isProvider ? 'Cotações Participante' : 'Cotações de Fornecedores',
                icon: 'shopping-cart-outline',
                link: '/pages/quote-provider/list',
                children: undefined,
            },
            {
                title: 'Pedidos de Compra',
                icon: 'shopping-bag-outline',
                link: '/pages/orders/list',
                children: undefined,
            }
        ];

        if (this.isAdmin === true) {
            return [...dashboard, ...adminMenu, ...buyerMenu, ...providerMenu];
        } else if (this.isBuyer === true) {
            return [...dashboard, ...buyerMenu];
        } else {
            return [...dashboard, ...providerUser, ...providerMenu];
        }
    }
}
