import { Injectable } from '@angular/core';
import { User } from '../interfaces/common/users';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  private _user?: User;

  protected userState$ = new BehaviorSubject(this.user);

  get user(): User { return this._user; }

  set user(value: User) {
    this._user = value;
    this.changeUserState(value);
  }

  onUserStateChange(): Observable<User> {
    return this.userState$.pipe(share());
  }

  changeUserState(user: User): void {
    this.userState$.next(user);
  }

  setSetting(themeName: string): void {
    if (this._user) {
      this._user.settings = { themeName: themeName };
      this.changeUserState(this.user);
    }
  }
}
