import { Observable } from 'rxjs';
import { GridData } from '../common/gridData';
import { DataSource } from 'ng2-smart-table/lib/lib/data-source/data-source';
import { Company, RequestResponse } from '../portal/company';
import { QuoteProvider } from './quote-provider';
import { HangfireResponse } from './hangfire';
import { HangfireStateEnum } from '../../enums/hangfireStateEnum';

export interface Quote {
  id: number;
  numQuote: string;
  groupCompany: string;
  dateCreation: Date;
  buyerGroup: string;
  coinId: string;
  dateLimit: Date;
  dateStart: Date;
  paymentConditionId: string;
  observation: string;
  status: string;
  formPayment: string;
  quoteProviders: QuoteProvider[];
  companyBillingId: string;
  companyDeliveryId: string;
  companyBilling: Company;
  companyDelivery: Company;
}

export interface QuoteGrid {
  id: number;
  numQuote: string;
  groupCompany: string;
  dateCreation: Date;
  buyerGroup: string;
  dateLimit: Date;
  status: string;
  providerName: string;
  providerCpfCnpj: string;
}

export interface TransactionHistoricDTO {
  description: string;
  dateRegister: Date;
  sourceObject: string;
  idObject: string;
  status: string;
  userId: string;
}

export abstract class QuoteData {
  abstract get gridDataSource(): DataSource;
  abstract list(pageNumber: number, pageSize: number): Observable<GridData<QuoteGrid>>;
  abstract get(id: number): Observable<Quote>;
  abstract update(request: Quote): Observable<RequestResponse>;
  abstract create(request: Quote): Observable<RequestResponse>;
  abstract delete(id: number): Observable<boolean>;
  abstract getNextNumQuote(): Observable<string>;
  abstract submitQuoteToProvider(id: number): Observable<Quote>;
  abstract changeStatus(objectHistoric: TransactionHistoricDTO): Observable<boolean>;
  abstract quoteLog(filter: number): Observable<RequestResponse<HangfireResponse[]>>;
}
