import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserStore } from '../../../@core/stores/user.store';
import { SettingsData } from '../../../@core/interfaces/common/settings';
import { User } from '../../../@core/interfaces/common/users';
import { ChatService } from '../../../pages/chat/chat.service';

export interface UserInfo {
    name: string;
    title: string;
}

export interface UserMenu {
    title: string,
    link: string,
    queryParams?: {
        profile: boolean
    }
}

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    isMobile: boolean = this.layoutService.isMobile();
    theme: string = this.themeService.currentTheme;
    logo: string = this.changeLogo(this.theme);
    isReady: boolean = false;
    userInfo: UserInfo = null;
    userMenu: UserMenu[] = null;
    user: User = null;

    private readonly roleTitle: Map<number, string> = new Map<number, string>([
        [1, 'Administrador'],
        [2, 'Comprador'],
        [3, 'Fornecedor']
    ]);

    get isDefault(): boolean { return this.theme === 'default'; }

    get isDark(): boolean { return this.theme === 'dark'; }

    get chatIcon(): boolean {
        return this.chatService.bShowHideChatListIcon;
    }

    constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private userStore: UserStore,
        private settingsService: SettingsData,
        private layoutService: LayoutService,
        private chatService: ChatService) {
    }

    async ngOnInit(): Promise<void> {
        this.userStore.onUserStateChange()
            .pipe(takeUntil(this.destroy$))
            .subscribe(async user => {
                this.user = user;
                this.setUserInfo();
                this.setMenuItems();
                this.isReady = true;
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private setUserInfo(): void {
        this.user !== null ? this.setInfo() : this.setDefaultInfo();
    }

    private setInfo(): void {
        this.userInfo = {
            name: this.user.firstName.length > 15 ? `${this.user.firstName.slice(0, 15)}...` : this.user.firstName,
            title: this.roleTitle.get(this.user.userRoles[0].roleId)
        }
    }

    private setDefaultInfo(): void {
        this.userInfo = {
            name: 'Usuário',
            title: 'Desconhecido'
        };
    }

    private setMenuItems(): void {
        this.userMenu = [
            { title: 'Perfil', link: this.user ? '/pages/users/current/' : '', queryParams: { profile: true } },
            { title: 'Alterar Senha', link: '/auth/reset-password' },
            { title: 'Sair', link: '/auth/logout' },
        ];
    }

    public changeTheme(themeName: string): void {
        this.userStore.setSetting(themeName);
        this.settingsService.updateCurrent(this.userStore.user.settings)
            .pipe(takeUntil(this.destroy$))
            .subscribe();
        this.themeService.changeTheme(themeName);
        this.logo = this.changeLogo(themeName);
        this.theme = this.themeService.currentTheme;
    }


    private changeLogo(themeName: string) : string {
        return themeName === 'default' ?
            "../../../../assets/images/logo.fw.png" :
            "../../../../assets/images/logo_white.fw.png";
    }

    toggleSidebar(): void {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();
    }

    navigateHome(): void {
        this.menuService.navigateHome();
    }
}
