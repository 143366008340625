import { Component, OnInit, ViewChild } from '@angular/core';
import { PagesMenu } from '../../../pages/pages-menu';
import { LayoutService } from '../../../@core/utils';
import { Router } from '@angular/router';
import { NbThemeService } from '@nebular/theme';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  menuButtons: any[] = [];
  isMobile: boolean = this.layoutService.isMobile();
  isDarkTheme: boolean;
  lastLink: string = '';

  constructor(private pagesMenu: PagesMenu, private layoutService: LayoutService, private router: Router, private themeService: NbThemeService) { }

  async ngOnInit(): Promise<void> {
    this.menuButtons = await this.pagesMenu.getMenu2().toPromise();
    this.isDarkTheme = this.themeService.currentTheme == 'dark';

    this.themeService.onThemeChange().subscribe((theme) =>
      this.isDarkTheme = theme.name == 'dark'
    );
  }

  setLink(link: string) {
    this.lastLink = link;
  }

  getTagId(title: string) {
    return title.toLowerCase().split(' ').join('-');
  }

  getSelectedColor(link: string, tag: string) {
    const currentLink = window.location.href;

    if (tag == 'button') {
      return currentLink.includes(link) || this.lastLink.includes(link) ? '#3366ff' : 'rgb(143, 155, 179)';
    } else if (tag == 'span') {
      return currentLink.includes(link) || this.lastLink.includes(link) ? '#3366ff' : this.isDarkTheme ? '#ffffff' : '#222b45';
    }
  }

  getSelectedColorDropup(dropup: any[]) {
    const currentLink = window.location.href;
    const links: string[] = dropup.map((x) => x.link);
    return links.some((x) => currentLink.includes(x) || this.lastLink.includes(x)) ? 'primary' : '';
  }

  hideMenu(title: string) {
    document.getElementById(this.getTagId(title)).click();
  }

  getBackgroundDropup(link: string) {
    const style = this.isDarkTheme ?
      'border: 1px solid #101426; border-bottom: none; margin-bottom: -3px; background-color: ' :
      'border: 1px solid rgb(213 208 208); border-bottom: none; margin-bottom: -3px; background-color: ';
    
    return style + this.backgroundColor(link) + ';';
  }

  private backgroundColor(link: string) {
    const currentLink = window.location.href;

    if (this.isDarkTheme) {
      return currentLink.includes(link) || this.lastLink.includes(link) ? 'rgb(28 35 61)' : '#222b45'
    } else {
      return currentLink.includes(link) || this.lastLink.includes(link) ? '#f7f9fc' : '#ffffff';
    }
  }
}