<nb-card>
  <nb-card-header>Atenção</nb-card-header>

  <nb-card-body>
    {{ mensagem }}
  </nb-card-body>
  
  <nb-card-footer class="mobile-modal-footer">
    <button class="cancel" nbButton status="danger" (click)="cancel()">Cancelar</button>
    <button nbButton status="success" (click)="submit()">Confirmar</button>
  </nb-card-footer>
</nb-card>