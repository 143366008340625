import { Component, Input, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMAIL_PATTERN } from '../../../@auth/components';
import { ProviderData } from '../../../@core/interfaces/portal/provider';
import { takeUntil } from 'rxjs-compat/operator/takeUntil';
import { Subject } from 'rxjs';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'Invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
})
export class InviteComponent {
  emailValue: string = '';
  loadingButton: boolean = false;
  protected readonly unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private providerService: ProviderData,
    protected ref: NbDialogRef<InviteComponent>) { }

  sendEmail() {
    this.loadingButton = true;
    const observable = this.providerService.inviteProvider({ email: this.emailValue });

    observable.subscribe(response => {
      if (response.status == '200') {
        this.toastrService.success(response.mensagem, `Convite enviado ao fornecedor com sucesso!`);
        this.cancel();
      } else {
        this.toastrService.danger(response.mensagem, 'Erro ao enviar convite ao fornecedor, tente novamente.');
      }
      this.loadingButton = false;
    });
  }

  get validEmail(): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(this.emailValue);
  }

  cancel() {
    this.ref.close(0);
  }
}