import { User, UserData } from "../../@core/interfaces/common/users";
import { UserStore } from "../../@core/stores/user.store";
import { Injectable } from "@angular/core";
import { NbJSThemesRegistry, NbThemeService } from "@nebular/theme";

@Injectable()
export class InitUserService {
  constructor(
    protected userStore: UserStore,
    protected userService: UserData,
    protected jsThemes: NbJSThemesRegistry,
    protected themeService: NbThemeService
  ) {}

  async initCurrentUser(): Promise<User> {
    const user = await this.userService.getCurrentUser().toPromise();

    this.userStore.user = user;
    if (this.jsThemes.has(user?.settings?.themeName ?? '')) {
      this.themeService.changeTheme(user.settings.themeName);
    }

    return user;
  }
}
