<div class="row">
  <div class="col-6">
    <h2 [ngStyle]="isMobile ? {fontSize: '1.75rem'} :  ''" class="mb-3 mt-0">Registro - Cadastro de Fornecedor</h2>
  </div>
  <div class="col-6 d-flex justify-content-end">
    <nb-icon pack="eva" icon="question-mark-circle" class="helpIcon" nbTooltip="Campos destacados com asterisco (*) são obrigatórios." nbTooltipPlacement="left" nbTooltipAdjustment="counterclockwise"></nb-icon>
  </div>
</div>

<div [formGroup]="registerForm" aria-labelledby="title">
  <h6 class="grid-h">
    <nb-icon nbPrefix pack="eva" icon="person-outline"></nb-icon> Informações gerais
  </h6>
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label for="nomeFornec" class="label">Nome / Razão Social <span class="ast">*</span></label>
        <input type="text" nbInput fullWidth id="nomeFornec" formControlName="nomeFornec" [status]="nomeFornec?.touched && nomeFornec?.errors?.required ? 'danger' : 'primary' " class="form-control" placeholder="" />
        <ngx-validation-message label="Nome / Razão Social" [showRequired]="nomeFornec?.touched && nomeFornec?.errors?.required" [showMaxLength]="nomeFornec?.hasError('maxlength')" [showMinLength]="nomeFornec?.hasError('minlength')" maxLength="40" minLength="4"></ngx-validation-message>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <label for="nomeFornecFantasia" class="label">Nome Fantasia</label>
        <input type="text" nbInput fullWidth id="nomeFornecFantasia" formControlName="nomeFornecFantasia" [status]="nomeFornecFantasia?.hasError('maxlength') ? 'danger' : 'primary' " class="form-control" placeholder="" />
        <ngx-validation-message label="Nome Fantasia" [showMaxLength]="nomeFornecFantasia?.hasError('maxlength')" maxLength="40"></ngx-validation-message>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <label for="cnpjFornec" class="label">CNPJ <span class="ast">*</span></label>
        <input type="text" nbInput fullWidth [textMask]="{ mask: MASKS.cnpj.textMask }" id="cnpjFornec" formControlName="cnpjFornec" [status]="cnpjFornec?.touched && cnpjFornec?.errors?.required ? 'danger' : 'primary' " class="form-control" placeholder="" />
        <ngx-validation-message label="CNPJ" [showPattern]="cnpjFornec?.hasError('cnpj')" [showRequired]="cnpjFornec?.touched && cnpjFornec?.errors?.required"></ngx-validation-message>
      </div>
    </div>
  </div>

  <h6 class="grid-h">
    <nb-icon nbPrefix pack="eva" icon="pin-outline"></nb-icon> Endereço
  </h6>
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label for="cepFornec" class="label">CEP <span class="ast">*</span></label>
        <input type="text" nbInput fullWidth id="cepFornec" formControlName="cepFornec" (input)="checkAndFindAddress($event.target.value)" [status]="cepFornec?.touched && cepFornec?.errors?.required ? 'danger' : 'primary' " class="form-control" placeholder="0000000" cep [textMask]="{ mask: MASKS.cep.textMask }" />
        <ngx-validation-message label="CEP" [showRequired]="cepFornec?.touched && cepFornec?.errors?.required" [showMaxLength]="cepFornec?.hasError('maxlength')" [showMinLength]="cepFornec?.hasError('minlength')" maxLength="10" minLength="8"></ngx-validation-message>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <label for="ruaFornec" class="label">Logradouro <span class="ast">*</span></label>
        <input type="text" nbInput fullWidth id="ruaFornec" formControlName="ruaFornec" [status]="ruaFornec?.touched && ruaFornec?.errors?.required ? 'danger' : 'primary' " class="form-control" placeholder="" />
        <ngx-validation-message label="Endereço" [showRequired]="ruaFornec?.touched && ruaFornec?.errors?.required" [showMaxLength]="ruaFornec?.hasError('maxlength')" [showMinLength]="ruaFornec?.hasError('minlength')" maxLength="60" minLength="4"></ngx-validation-message>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <label for="complementoFornec" class="label">Complemento</label>
        <input type="text" nbInput fullWidth id="complementoFornec" formControlName="complementoFornec" [status]="complementoFornec?.hasError('maxlength') ? 'danger' : 'primary' " class="form-control" placeholder="" />
        <ngx-validation-message label="Complemento" [showMaxLength]="complementoFornec?.hasError('maxlength')" maxLength="10"></ngx-validation-message>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-3">
      <div class="form-group">
        <label for="bairroFornec" class="label">Bairro <span class="ast">*</span></label>
        <input type="text" nbInput fullWidth id="bairroFornec" formControlName="bairroFornec" [status]="bairroFornec?.touched && bairroFornec?.errors?.required ? 'danger' : 'primary' " class="form-control" placeholder="" />
        <ngx-validation-message label="Bairro" [showRequired]="bairroFornec?.touched && bairroFornec?.errors?.required" [showMaxLength]="bairroFornec?.hasError('maxlength')" [showMinLength]="bairroFornec?.hasError('minlength')" maxLength="40" minLength="4"></ngx-validation-message>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="form-group">
        <label for="cidadeFornec" class="label">Cidade <span class="ast">*</span></label>
        <input type="text" nbInput fullWidth id="cidadeFornec" formControlName="cidadeFornec" [status]="cidadeFornec?.touched && cidadeFornec?.errors?.required ? 'danger' : 'primary' " class="form-control" placeholder="" />
        <ngx-validation-message label="Cidade" [showRequired]="cidadeFornec?.touched && cidadeFornec?.errors?.required" [showMaxLength]="cidadeFornec?.hasError('maxlength')" maxLength="40"></ngx-validation-message>
      </div>
    </div>
    <div class="col-6 col-sm-3">
      <div class="form-group">
        <label for="ufFornec" class="label">UF <span class="ast">*</span></label>
        <input type="text" nbInput fullWidth id="ufFornec" formControlName="ufFornec" [status]="ufFornec?.touched && ufFornec?.errors?.required ? 'danger' : 'primary' " class="form-control" placeholder="" />
        <ngx-validation-message label="UF" [showRequired]="ufFornec?.touched && ufFornec?.errors?.required" [showMaxLength]="ufFornec?.hasError('maxlength')" [showMinLength]="ufFornec?.hasError('minlength')" maxLength="2" minLength="2"></ngx-validation-message>
      </div>
    </div>
    <div class="col-6 col-sm-3">
      <div class="form-group">
        <label for="paisFornec" class="label">País <span class="ast">*</span></label>
        <input type="text" nbInput fullWidth id="paisFornec" formControlName="paisFornec" [status]="paisFornec?.touched && paisFornec?.errors?.required ? 'danger' : 'primary' " class="form-control" placeholder="" />
        <ngx-validation-message label="País" [showRequired]="paisFornec?.touched && paisFornec?.errors?.required" [showMaxLength]="paisFornec?.hasError('maxlength')" [showMinLength]="paisFornec?.hasError('minlength')" maxLength="2" minLength="2"></ngx-validation-message>
      </div>
    </div>
  </div>

  <h6 class="grid-h">
    <nb-icon nbPrefix pack="eva" icon="phone-outline"></nb-icon> Contato
  </h6>
  <div class="row">
    <div class="col-sm-3">
      <div class="form-group">
        <label for="telefoneFornec" class="label">Telefone <span class="ast">*</span></label>
        <nb-form-field>
          <input type="text" nbInput fullWidth id="telefoneFornec" formControlName="telefoneFornec" [textMask]="{ mask: MASKS.telefone.textMaskFunction }" [status]="telefoneFornec?.touched && telefoneFornec?.errors?.required ? 'danger' : 'primary' " class="form-control" placeholder="(00) 00000-0000" />
        </nb-form-field>
        <ngx-validation-message label="Telefone" [showPattern]="telefoneFornec?.hasError('telefone')" [showRequired]="telefoneFornec?.touched && telefoneFornec?.errors?.required"></ngx-validation-message>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="form-group">
        <label for="emailFornec" class="label">Email <span class="ast">*</span></label>
        <nb-form-field>
          <input nbInput type="text" placeholder="Email" fullWidth id="emailFornec" formControlName="emailFornec" [status]="(emailFornec?.touched && emailFornec?.errors?.required) || emailFornec?.hasError('pattern') ? 'danger' : 'primary' " class="form-control" />
        </nb-form-field>
        <ngx-validation-message label="Email" [showRequired]="emailFornec?.touched && emailFornec?.errors?.required" [showPattern]="emailFornec?.hasError('pattern')"></ngx-validation-message>
      </div>
    </div>
  </div>
</div>

<button nbButton [nbSpinner]="loadingButtonRegister" status="success" (click)="save()" hero nbSpinnerStatus="control" [disabled]="!registerForm.valid || loadingButtonRegister">
  <span [ngStyle]="loadingButtonRegister ? {visibility: 'hidden'} : ''"> <nb-icon icon="save-outline"></nb-icon> Registrar </span>
</button>
