import { Component, OnInit } from "@angular/core";
import { InitUserService } from "./@theme/services/init-user.service";
import { StorageService } from "./@core/backend/portal/services/storage.service";

@Component({
  selector: "ngx-app",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {
  constructor(
    private storageService: StorageService,
    private initUserService: InitUserService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initUser();
  }

  async initUser(): Promise<void> {
    this.storageService.getAPIURL().subscribe((x) => {
      this.storageService.url = `${this.storageService.url == undefined ? "" : this.storageService.url}${x}`;
      localStorage.setItem('api_url', this.storageService.url);
    });
    
    await this.initUserService.initCurrentUser();
  }
}
