<div class="container text-center">
  <img [src]="logo" alt="logo" height="100"/>
</div>


<h1 id="title" class="title">Recuperar Senha</h1>
<p class="sub-title">Informe seu endereço de e-mail e nós enviaremos uma nova senha para que possa acessar sua conta.</p>

<nb-alert *ngIf="showMessages?.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title">
    <nb-icon icon="alert-triangle-outline"></nb-icon>
    <b> Erro! </b>
  </p>
  <ul class="alert-message-list">
    <li class="alert-message">Houve um erro ao recuperar sua senha, por favor tente novamente...</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages?.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title">
    <nb-icon icon="email-outline"></nb-icon>
    <b> Senha Restaurada! </b>
  </p>
  <ul class="alert-message-list">
    <li class="alert-message">Sua senha foi restaurada com sucesso, um e-mail foi enviado com a nova senha. Redirecionando...</li>
  </ul>
</nb-alert>

<div [formGroup]="requestPasswordForm" aria-labelledby="title">
  <div class="form-control-group">
    <label class="label" for="input-email">E-mail</label>
    <input  nbInput autofocus fullWidth fieldSize="large" id="input-email" formControlName="email" [attr.aria-invalid]="email.invalid && email.touched ? true : null" placeholder="Endereço de e-mail">
  </div>

  <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !requestPasswordForm.valid" [class.btn-pulse]="submitted" (click) = "requestPass()">
    Recuperar Senha
  </button>
</div>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Voltar para tela de login</a></p>
</section>

